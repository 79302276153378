<template>
<div class="containerBox">
    <div class="searchBar">
    <input class="form-control" type="text" placeholder="장치이름을 입력하세요" @input="searchData($event)" />
    <select class="selects" v-model="selected" @change="onClickTab(selected)">
        <option value="">장소를 선택하세요</option>
        <option v-for="(site, index) in siteTables" :value="index" :key="index">{{ site.title }}</option>
    </select>
    </div>

    <div class="searchResult">
    <div class="group" v-for="(site, index) in siteTables" :key="index">
        <div class="group-item" v-for="(data, index) in site.data" :key="index" @click="sendEventData(data.guid)">
        <div class="imgWrapper" :style="{ border: calcBorderColor(data.level) }">
        <!-- <div class="devicetitle"><router-link :to="`srds_device/${data.guid}`">{{ data.name }}</router-link></div> -->
            <div v-if="data.level == 2" class="devicetitle" style="color: #FF8C00">{{ data.name }}</div>
            <div v-else-if="data.level == 3" class="devicetitle" style="color: #E50000">{{ data.name }}</div>
            <div v-else class="devicetitle" >{{ data.name }}</div>
            
            <div class="img" v-html="findCameraImg(data)"></div>
            <div class="battery" v-html="findBatteryImg(data)"></div>
            <div class="rfstatus" v-html="findRFImg(data)"></div>
        </div>
        </div>
    </div>
    </div>
</div>
</template>

<script>

import loopback from '@/services/loopback';
import moment from 'moment';
import EventBus from '@/services/EventBus'
import utils from '@/services/utils';
import { IMAGES } from "@/constants/constant"
import Device from "@/views/dashboard/Device.vue";
import CronParser from 'cron-parser'


import {
DEVICE_TABLE_OPTIOINS, SRDS_DEVICE_TABLE_COLUMS, LEAF_TYPES
} from "@/constants/deviceSettings";

export default {
name: 'Device',
extends: Device,
data() {
    return {
    active_site_index: 0,
    siteTables: [],
    dashboard: {},
    sensor_type: utils.getSensorTypes(),
    selected: 0,
    searchResults: [],
    }
},
mounted() {
    this.selected = this.user.active_site_index
    this.onClickTab(this.active_site_index)
},
methods: {
    sortedDataArray(arr) {
    let sortedArray = arr.sort((a, b) => {
        // Extract numeric values from the "name" property
        const numA = parseInt(a.name);
        const numB = parseInt(b.name);
        // Compare numeric values in descending order
        return numA - numB;
    });
    return sortedArray;
    },
    sendEventData(guid) {
    EventBus.$emit("send", guid)
    },
    setTableData() {
    for (let item of this.siteTables) {
        if (item.device_count > 0) {
        this.searchResults = item
        }
    }
    },
    searchData(event) {
    this.setTableData()
    let keyword = event.target.value

    let len = this.searchResults.data.length
    for (let i = 0; i < len; i++) {
        if (
        this.searchResults.data[i].name.includes(keyword) === false &&
        this.searchResults.data[i].site.includes(keyword) === false
        ) {
        document.querySelectorAll('.group-item')[i].style.display = "none"
        } else {
        document.querySelectorAll('.group-item')[i].style.display = 'flex'
        }
    }
    },
    makeTable(site_guids) {
    if (site_guids.length === this.siteTables.length) {
        return;
    }
    this.siteTables = [];

    DEVICE_TABLE_OPTIOINS.sortName = "name";
    DEVICE_TABLE_OPTIOINS.sortOrder = "asc";

    for (var i = 0; i < site_guids.length; i++) {
        var table = {
        options: DEVICE_TABLE_OPTIOINS,
        columns: SRDS_DEVICE_TABLE_COLUMS,
        title: '',
        data: []
        }
        // _.find(table.columns, { field: 'site' }).formatter = this.siteFormatter;
        _.find(table.columns, { field: 'name' }).formatter = this.nameFormatter;
        _.find(table.columns, { field: 'sensor' }).formatter = this.sensorFormatter;
        _.find(table.columns, { field: 'level' }).formatter = this.imageFormatter2;
        _.find(table.columns, { field: 'sensor_time' }).formatter = utils.dateFormatter;
        this.siteTables.push(table)
    }
    },
    imageFormatter(value, row, sensor_data) {
    if (_.isUndefined(value) || value.length < 1) {
        return this.buildsensorFormatter(sensor_data, row);
    }

    var color = "0,0,0";
    var text_color = "lightgray";
    var label = '';
    var status = '측정일시';
    sensor_data.data.forEach(function (v) {
        if (!_.get(row, "empty", false)) {
        if (v.id === 0) v.id = 1;
        switch (v.type) {
            case 9:
            status = "충격감지";
            color = v.value == 1 ? "255,0,0" : "0,0,0";
            text_color = "white";
            break;
        }
        if (new Date(row.prev_time).getTime() >= new Date(row.sensor_time).getTime()) {
            var diffMSec = new Date(row.prev_time).getTime() - new Date(row.sensor_time).getTime();
            var diffDate = diffMSec / (24 * 60 * 60 * 1000);
            if (diffDate >= 1) {
            color = "0,0,0";
            status = '미수신 {0}일 경과'.format(Math.floor(diffDate));
            if (diffDate >= 7) {
                status = '점검필요'.format(Math.floor(diffDate));
                text_color = "orange";
            }
            }
        }

        label = '<div class="sensor_time" style="color:{0}; background-color: rgba({1},.7);"><p>{2}</p>{3}</div>'.format(text_color, color, status, moment(row.sensor_time).format("'YY.MM.DD HH:mm"));
        }
    })
    var images = []
    var device_url = this.getDeviceUrl(row);
    for (var i = 0; i < value.length; i++) {
        var el = value[i]
        if (_.isUndefined(el.storage)) {
        continue
        }
        var url = loopback.defaults.baseURL + '/' + el.storage + 's/' + el.device_guid + '/download/' + el.file_name;
        images.push({
        url: url,
        label: label,
        created_at: el.created_at
        })
    }
    images = _.sortBy(images, ['created_at']).reverse();
    var target_id = 'img_' + row.guid
    var result = '<div id="' + target_id + '" class="carousel slide" data-ride="carousel" data-interval="5000">' +
        '<ol class="carousel-indicators hidden">';
    result += '</ol>'
    var img = '<a href="{0}"><img src="{1}" alt="" style="width: 100%; height: 100%; border-radius: 10px; border: 2px solid #eee; ;" /></a>'
    var caption = '<div class="carousel-caption"><p>{0}</p></div></div>'
    result += img.format(device_url, images[0].url)
    result += caption.format(images[0].label)

    result += '<style scoped>'
    result += '.carousel-indicators {'
    result += '  bottom: -25px;'
    result += '  z-index: 10;'
    result += '}'
    result += '.carousel-inner {'
    result += '  width: 100%;'
    result += '  max-height: 256px !important;'
    result += '  background-color: silver !important;'
    result += '}'
    result += '.carousel-caption {'
    result += '  bottom: -10px;'
    result += '  z-index: 10;'
    result += '  padding: 0px;'
    result += '}'
    result += '</style>'
    // console.log('[Device Carousel html] : ', result)
    return result;
    },
    imageFormatter2(value, row, index) {
    var imgsrc;
    var status_text = "";

    if (value == 1) {
        imgsrc = IMAGES.DN.name;
        status_text = IMAGES.DN.status;
    }
    else if (value == 2) {
        // console.log("level2");                
        imgsrc = IMAGES.DC.name;
        status_text = IMAGES.DC.status;
    }
    else if (value == 3) {
        //console.log("level3");
        imgsrc = IMAGES.DW.name;
        status_text = IMAGES.DW.status;
    }
    if (imgsrc == undefined)
        imgsrc = "img/humidity-lack.png";

    // imgsrc = 'img/battery-good.png'(DN.name)
    // status_text = '정상'
    return '<div class="containerBox"><img class="img-responsive" src=' + imgsrc + ' height=' + IMAGES.size.height + 'width=' + IMAGES.size.width + ' / ><div class="threshold-text-box"><h6 class="threshold-dataNumber">' + status_text + '</h6></div></div> ';
    },
    levelFormatter(row) {
    var level = "";
    var result_x = "";
    var result_y = "";
    var thresholds = utils.getThresholds();
    try {
        var level0 = _.find(thresholds, { sensor_type: 8, level: 0 }).min; //0
        var level2 = _.find(thresholds, { sensor_type: 8, level: 2 }).min; //3
        var level3 = _.find(thresholds, { sensor_type: 8, level: 3 }).min; //5
    } catch (err) {
        return;
    }

    row.sensor.data.forEach(function (e) {
        if (e.type === 8) {
        if (e.id === 1)
            result_x = Math.abs(e.value - row.standard.x).toFixed(2);
        else if (e.id === 2)
            result_y = Math.abs(e.value - row.standard.y).toFixed(2);
        }
    })

    level = IMAGES.DN.level;

    if (result_x >= level2 && result_x < level3) {
        level = IMAGES.DC.level;
    }
    else if (result_x >= level3) {
        level = IMAGES.DW.level;
    }
    else if (result_y >= level2 && result_y < level3) {
        level = IMAGES.DC.level;
    }
    else if (result_y >= level3) {
        level = IMAGES.DW.level;
    }
    return level;
    },
    sensorFormatter(value, row, index) {
    var self = this;
    if (row.service_type === 'srds') {
        return self.imageFormatter(row.images, row, value);
    }
    return self.buildsensorFormatter(value, row);

    },
    buildsensorFormatter(value, row) {
    var self = this
    var result = "";
    value.data = _.sortBy(value.data, ['type']);
    value.data.forEach(function (v) {
        if (!_.get(row, "empty", false)) {
        if (v.id === 0) v.id = 1;
        var description = _.find(self.sensor_type, {
            code: v.type,
            id: v.id
        });
        if (!description) return

        var desc = "";
        switch (v.type) {
            case 1:
            //desc = '배터리:' + v.value + description.unit + ' '; 
            break;
            case 8:
            if (v.id === 1) desc = 'x축:' + v.value + description.unit + ' ';
            else if (v.id === 2) desc = 'y축:' + v.value + description.unit + ' ';
            else if (v.id === 3) desc = 'z축:' + v.value + description.unit + ' ';
            break;
            case 9:
            desc = v.value == 1 ? "<br><span style = 'color: red; font-weight: bold;'> 충격감지 </span>" : '';
            break;
        }
        result += desc;
        }
        else
        result = "측정값 없음";
    })
    return "<a>" + result + "</a>";
    // return `<a href='#/{0}device/{1}'><img src="{2}"style="width:180px; height:100px; border-radius:10px;" border: 2px solid gray; /></a>`.format(this.prefix, row.guid, '/img/logo3.bd1ef8a4.png') 

    },
    onClickTab(index) {
    var active_site_index = _.get(this.user, "active_site_index", -1);
    if (active_site_index != index) {
        this.user.active_site_index = index;
        this.user.active_site_name = this.siteTables[index].title;
        utils.setStorage("userInfo", this.userInfo);
    }
    return this.getDeviceList(index)
        .then((siteTable) => {
        let data = {
            site_index : index,
            site : siteTable.title
        };
        EventBus.$emit("send", data)
        return Promise.resolve(siteTable)
            .then(() => {
            if (!this.$parent.$refs.eventView) return null;
            var eventTable = this.$parent.$refs.eventView.getTable();
            eventTable.data.forEach((event) => {
                this.$parent.$refs.deviceView.setMarkerImage(event);
            });
            })
        })
        .catch(err => {
        // ignore
        })
    },
    onEvent(event, value, row) {
    var site_guid = event.site;
    var active_site_index = _.get(this.user, "active_site_index", -1);
    var index = _.indexOf(this.user.active_site_guids, site_guid);
    if (active_site_index !== index) {
        $('[href="#site_tab' + index + '"]').tab('show');
        this.onClickTab(index).then(res => {
        if (res != null) this.setMarkerImage(event)
        })
    } else {
        this.setMarkerImage(event)
    }

    },
    onClickRow(field, value, row) {
    this.$parent.$refs.googleMap.setCenter(field)
    },
    setMarkerImage(event) {
    this.$parent.$refs.googleMap.setMarkerImage(event)
    },
    getDeviceList(index, limit, skip) {
    var self = this;
    if (typeof limit === 'undefined') limit = 50
    if (typeof skip === 'undefined') skip = 0

    var default_site_guid = _.get(this.user, "default_site_guid");
    var active_site_index = _.get(this.user, "active_site_index", 0)

    var data = {
        limit: limit,
        skip: skip,
        site_guid: _.isEmpty(this.user.active_site_guids) ? this.user.site_guids : this.user.active_site_guids,
        active_site_index: active_site_index
    }

    // this.makeTable(data.site_guid)
    return loopback
        .method('sites', 'getDeviceList', data)
        .then(res => {
        // console.log('Device::getDeviceList: ', JSON.stringify(res,null,2))
        self.makeTable(_.map(res, 'site_guid'))
        self.user.active_site_guids = []
        res.forEach(function (d, i) {
            var rows = [];
            d.data.forEach(function (e) {
            var interval = CronParser.parseExpression(e.upload_cron)
            var data = {
                site: d.Site.name,
                site_guid: d.Site.guid,
                site_index: i,
                center_device_guid: d.Site.center_device_guid,
                latitude: e.latitude,
                longitude: e.longitude,
                name: e.name,
                service_type: e.service_type,
                address: e.address,
                sensor: e.sensor,
                standard: e.standard,
                empty: e.sensor.empty,
                sensor_time: e.sensor.created_at,
                guid: e.guid,
                threshold: e.threshold,
                images: e.images,
                level: self.levelFormatter(e),
                prev_time: new Date(interval.prev()),
                periodic_pic_active: e.periodic_pic_active
            }
            rows.push(data)
            });
            var active_site_index = _.get(self.user, "active_site_index", -1);
            if (active_site_index != -1 && self.active_site_index != active_site_index) {
            if (active_site_index < res.length)
                self.active_site_index = active_site_index;
            } else {
            if (default_site_guid == d.site_guid) {
                self.active_site_index = i;
            }
            }

            if (_.indexOf(self.user.active_site_guids, d.site_guid) === -1) {
            self.user.active_site_guids.push(d.site_guid);
            }
            self.siteTables[i].title = d.Site.name;
            self.siteTables[i].data = rows;
            self.siteTables[i].device_count = rows.length;
            self.siteTables[i].address = d.Site.address;
        });
        var siteTable = self.siteTables[self.active_site_index]
        self.user.active_site_name = siteTable.title;
        utils.setStorage("userInfo", self.userInfo);
        if (!_.isUndefined(self.$parent.$refs.googleMap))
            self.$parent.$refs.googleMap.update(siteTable);

        self.$parent.$refs.weatherView.getWeatherData(self.active_site_index, siteTable.address)
        // self.$parent.$refs.weatherView2.getWeatherData(self.active_site_index, siteTable.address)
        // notify to parent : change
        self.$emit('change', siteTable)
        //siteTable.data.sort((a, b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0);
        self.sortedDataArray(siteTable.data);
        return Promise.resolve(siteTable)
        })
    }, // getDeviceList
    // findBatteryImg(data) {
    //   var result = "";
    //   var status_text = "";
    //   var imgsrc;
    //   var thresholds = utils.getThresholds();

    //   try {
    //     var level0 = _.find(thresholds, { sensor_type: 1, level: 0 }).min;
    //     var level1 = _.find(thresholds, { sensor_type: 1, level: 1 }).min;
    //     var level2 = _.find(thresholds, { sensor_type: 1, level: 2 }).min;
    //     var level3 = _.find(thresholds, { sensor_type: 1, level: 3 }).min;
    //   } catch (err) {
    //     return;
    //   }

    //   if (_.isUndefined(data.sensor)) {
    //     console.log("isUndefined");
    //     return;
    //   }

    //   data.sensor.data.forEach(function (e) {
    //     if (e.type === 1)
    //       result = e.value;
    //   })
    //   if (result > 4) {
    //     imgsrc = '<svg xmlns="http://www.w3.org/2000/svg" height="24" width="27" viewBox="0 0 576 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.--><path fill="#008000" d="M464 160c8.8 0 16 7.2 16 16V336c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V176c0-8.8 7.2-16 16-16H464zM80 96C35.8 96 0 131.8 0 176V336c0 44.2 35.8 80 80 80H464c44.2 0 80-35.8 80-80V320c17.7 0 32-14.3 32-32V224c0-17.7-14.3-32-32-32V176c0-44.2-35.8-80-80-80H80zm368 96H96V320H448V192z"/></svg>'
    //     status_text = '<div style="font-weight: bold; color:green;">' + IMAGES.BG.status + '</div>';
    //   }
    //   else if (result > level0 && result <= 4) {
    //     imgsrc = '<svg xmlns="http://www.w3.org/2000/svg" height="24" width="27" viewBox="0 0 576 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.--><path fill="#198754" d="M464 160c8.8 0 16 7.2 16 16V336c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V176c0-8.8 7.2-16 16-16H464zM80 96C35.8 96 0 131.8 0 176V336c0 44.2 35.8 80 80 80H464c44.2 0 80-35.8 80-80V320c17.7 0 32-14.3 32-32V224c0-17.7-14.3-32-32-32V176c0-44.2-35.8-80-80-80H80zm272 96H96V320H352V192z"/></svg>'
    //     status_text = '<div style="font-weight: bold; color:green;">' + IMAGES.BG.status + '</div>';
    //   }
    //   else if (result > 3.6 && result <= level0) {
    //     imgsrc = '<svg xmlns="http://www.w3.org/2000/svg" height="24" width="27" viewBox="0 0 576 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.--><path fill="#000000" d="M464 160c8.8 0 16 7.2 16 16V336c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V176c0-8.8 7.2-16 16-16H464zM80 96C35.8 96 0 131.8 0 176V336c0 44.2 35.8 80 80 80H464c44.2 0 80-35.8 80-80V320c17.7 0 32-14.3 32-32V224c0-17.7-14.3-32-32-32V176c0-44.2-35.8-80-80-80H80zm208 96H96V320H288V192z"/></svg>'
    //     status_text = '<div style="font-weight: bold;">' + IMAGES.BN.status + '</div>';
    //   }
    //   else if (result > level1 && result <= 3.6) {
    //     imgsrc = '<svg xmlns="http://www.w3.org/2000/svg" height="24" width="27" viewBox="0 0 576 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.--><path fill="#ffc107" d="M464 160c8.8 0 16 7.2 16 16V336c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V176c0-8.8 7.2-16 16-16H464zM80 96C35.8 96 0 131.8 0 176V336c0 44.2 35.8 80 80 80H464c44.2 0 80-35.8 80-80V320c17.7 0 32-14.3 32-32V224c0-17.7-14.3-32-32-32V176c0-44.2-35.8-80-80-80H80zm112 96H96V320h96V192z"/></svg>'
    //     status_text = '<div style="font-weight: bold; color:orange;">' + IMAGES.BC.status + '</div>';
    //   }
    //   else if (result > level2 && result <= level1) {
    //     imgsrc = '<svg xmlns="http://www.w3.org/2000/svg" height="24" width="27" viewBox="0 0 576 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.--><path fill="#ff0000" d="M464 160c8.8 0 16 7.2 16 16V336c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V176c0-8.8 7.2-16 16-16H464zM80 96C35.8 96 0 131.8 0 176V336c0 44.2 35.8 80 80 80H464c44.2 0 80-35.8 80-80V320c17.7 0 32-14.3 32-32V224c0-17.7-14.3-32-32-32V176c0-44.2-35.8-80-80-80H80zm112 96H96V320h96V192z"/></svg>'
    //     status_text = '<div style="font-weight: bold; color:red;">' + IMAGES.BW.status + '</div>';
    //   }
    //   else if (result <= level2) {
    //     imgsrc = '<svg xmlns="http://www.w3.org/2000/svg" height="24" width="27" viewBox="0 0 576 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.--><path fill="#ff0000" d="M464 160c8.8 0 16 7.2 16 16V336c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V176c0-8.8 7.2-16 16-16H464zM80 96C35.8 96 0 131.8 0 176V336c0 44.2 35.8 80 80 80H464c44.2 0 80-35.8 80-80V320c17.7 0 32-14.3 32-32V224c0-17.7-14.3-32-32-32V176c0-44.2-35.8-80-80-80H80zm112 96H96V320h96V192z"/></svg>'
    //     status_text = '<div style="font-weight: bold; color:red;">' + IMAGES.BW.status + '</div>';
    //   }
    //   else {
    //     imgsrc = '<svg xmlns="http://www.w3.org/2000/svg" height="24" width="27" viewBox="0 0 576 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.--><path fill="#ff0000" d="M80 160c-8.8 0-16 7.2-16 16V336c0 8.8 7.2 16 16 16H464c8.8 0 16-7.2 16-16V176c0-8.8-7.2-16-16-16H80zM0 176c0-44.2 35.8-80 80-80H464c44.2 0 80 35.8 80 80v16c17.7 0 32 14.3 32 32v64c0 17.7-14.3 32-32 32v16c0 44.2-35.8 80-80 80H80c-44.2 0-80-35.8-80-80V176z"/></svg>'
    //     status_text = '<div style="font-weight: bold; color:red;">' + '교체필요' + '</div>';

    //   }
    //   return `<div class="containerBox"><div>${imgsrc}</div><div style="font-weight: bold;">${status_text}</div></div>`
    // },
    findBatteryImg(data) {
    var result = "";
    // var status_text = "";
    var imgsrc;
    var thresholds = utils.getThresholds();
    try {
        var level0= _.find(thresholds, {sensor_type : 1, level : 0 }).min;
        var level1= _.find(thresholds, {sensor_type : 1, level : 1 }).min;
        var level2= _.find(thresholds, {sensor_type : 1, level : 2 }).min;
        var level3= _.find(thresholds, {sensor_type : 1, level : 3 }).min;
    } catch(err) {
        return;
    }
    if (_.isUndefined(data.sensor)){
        console.log("isUndefined");
        return;
    }

    data.sensor.data.forEach(function(e){
        if(e.type === 1)
        result = e.value;
    })

    if (result >= level0){ 
        //console.log("level0");
        imgsrc = `<i class="fa fa-battery-full fa-lg" style="color:#358038;"></i>`
        // status_text = IMAGES.BG.status;
    }
    else if (result >= level1 ){ 
        //console.log("level1");
        imgsrc = `<i class="fa fa-battery-three-quarters fa-lg" style="color:#358038;"></i>`
        // status_text = IMAGES.BN.status;
    }
    else if (result >= level2 ){
        //console.log("level2");                
        imgsrc = `<i class="fa fa-battery-half fa-lg" style="color:#fbc02d;"></i>`
        // status_text = IMAGES.BC.status;
    }
    else if (result >= level3 ){
        //console.log("level3");
        imgsrc = `<i class="fa fa-battery-quarter fa-lg" style="color:#E50000;"></i>`

    }
    else {
        //console.log("교체필요");              
        return `<div class="containerBox"><div style="margin-left: 4px;"><i class="fa fa-battery-quarter fa-lg" style="color:#e65100; margin-left: 15px;"></i></div></div>`  /*  <div><span style ='color: #e65100; font-weight: bold;'> 교체필요 </span></div> */
    }
    return `<div class="containerBox"><div style="margin-left: 4px;">${imgsrc}</div></div>` /* <div>${status_text}</div> */
    },
    calcBorderColor(value) {
    if(value == 1) {
        return ""
    } else if(value == 2) {
        return "5px solid rgb(255,140,0) "
    } else if(value == 3) {
        return "5px solid rgb(229,0,0) "
    } else return ""
    },
    // findRFImg(data) {
    //   var result = "";
    //   var status_text = "";
    //   var imgsrc;
    //   if (new Date(data.prev_time).getTime() <= new Date(data.sensor_time).getTime()) {
    //     imgsrc = '<svg xmlns="http://www.w3.org/2000/svg" height="24" width="27" viewBox="0 0 640 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.--><path fill="#008000" d="M54.2 202.9C123.2 136.7 216.8 96 320 96s196.8 40.7 265.8 106.9c12.8 12.2 33 11.8 45.2-.9s11.8-33-.9-45.2C549.7 79.5 440.4 32 320 32S90.3 79.5 9.8 156.7C-2.9 169-3.3 189.2 8.9 202s32.5 13.2 45.2 .9zM320 256c56.8 0 108.6 21.1 148.2 56c13.3 11.7 33.5 10.4 45.2-2.8s10.4-33.5-2.8-45.2C459.8 219.2 393 192 320 192s-139.8 27.2-190.5 72c-13.3 11.7-14.5 31.9-2.8 45.2s31.9 14.5 45.2 2.8c39.5-34.9 91.3-56 148.2-56zm64 160a64 64 0 1 0 -128 0 64 64 0 1 0 128 0z"/></svg>'
    //     status_text = "수신";
    //     return `<div class="containerBox"><div>${imgsrc}</div><span style ='color: green; font-weight: bold;'>${status_text}</span>`;
    //   }
    //   else {
    //     imgsrc = `<i class="ri-wifi-off-fill" style="color:rgb(230, 81, 0);"></i>`
    //     imgsrc = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path d="M12.0001 18.0002C12.7144 18.0002 13.3704 18.2498 13.8856 18.6666L12.0001 21.0002L10.1145 18.6666C10.6297 18.2498 11.2857 18.0002 12.0001 18.0002ZM2.80766 1.39355L20.4853 19.0712L19.0711 20.4854L15.3895 16.8046L15.1425 17.1108C14.2838 16.4162 13.1905 16.0002 12.0001 16.0002C10.81 16.0002 9.71712 16.4159 8.85862 17.11L6.97363 14.7761C8.24961 13.7443 9.84925 13.097 11.5964 13.0102L9.82328 11.2378C8.29284 11.5775 6.89439 12.2679 5.71658 13.2204L3.83099 10.8869C4.89946 10.0228 6.10763 9.3245 7.41633 8.8313L5.88489 7.29919C4.69082 7.83295 3.5793 8.51822 2.57479 9.33056L0.689453 6.99686C1.60358 6.25759 2.59156 5.60601 3.64058 5.05491L1.39345 2.80777L2.80766 1.39355ZM16.0844 11.8696L12.2165 8.00255L12.0001 8.00016C15.0948 8.00016 17.9369 9.08154 20.1693 10.887L18.284 13.2207C17.6163 12.6808 16.8777 12.225 16.0844 11.8696ZM12.0001 3.00016C16.2849 3.00016 20.22 4.49731 23.3109 6.99703L21.4254 9.3306C18.8497 7.24767 15.5706 6.00016 12.0001 6.00016C11.4284 6.00016 10.8642 6.03214 10.3091 6.09441L7.72455 3.511C9.09498 3.17714 10.5268 3.00016 12.0001 3.00016Z" fill="rgba(255,0,0,1)"></path></svg>'
    //     status_text = "미수신";
    //     return `<div class="containerBox"><div>${imgsrc}</div><span style ='color: rgb(230, 81, 0); font-weight: bold;'>${status_text}</span>`;
    //   }
    // },
    findRFImg(data) {
    var result = "";
    var status_text = "";
    var imgsrc;
    if (new Date(data.prev_time).getTime() <= new Date(data.sensor_time).getTime()){
        // imgsrc = `<i class="fa-solid fa-wifi" style="color:#2F7332;"></i>`
        // imgsrc = `<img src='@/assets/icons/wifi.png'>`
        // return `<div class="containerBox"><div>${imgsrc}</div><span style ='color: #2F7332; font-weight: bold;'>${status_text}</span>`;
        return `<div class="containerBox"><img src="images/wifi.png" width="18px" height="18px" /></div>`;
        
    }
    else{
        imgsrc = `<i class="ri-wifi-off-fill" style="color:#E50000;"></i>`
        return `<div class="containerBox"><div>${imgsrc}</div><span style ='color: #E50000; font-weight: bold;'>${status_text}</span>`;
    }
    },  
    findCameraImg(data) {
    let result
    let row = data
    if (_.isUndefined(data.images) || _.isEmpty(data.images)) {
        return `<a href='#/{0}device/{1}'><img src="{2}"style="width:100%; height:100%; border-radius:10px;" border: 2px solid #eee; /></a>`.format(this.prefix, row.guid, '/img/logo3.bd1ef8a4.png')
    }
    result = this.imageFormatter(row.images, row, row.sensor)

    return result
    },
    findLevelImg(value) {
    var status_text = "";
    var imgsrc;

    if (value == 1) {
        // imgsrc = `<i class="fa-solid fa-hill-rockslide fa-lg" style="color:green;"></i>`;
        // imgsrc = '<svg xmlns="http://www.w3.org/2000/svg" height="16" width="18" viewBox="0 0 576 512" style="color: green;"><path d="M252.4 103.8l27 48c2.8 5 8.2 8.2 13.9 8.2l53.3 0c5.8 0 11.1-3.1 13.9-8.2l27-48c2.7-4.9 2.7-10.8 0-15.7l-27-48c-2.8-5-8.2-8.2-13.9-8.2H293.4c-5.8 0-11.1 3.1-13.9 8.2l-27 48c-2.7 4.9-2.7 10.8 0 15.7zM68.3 87C43.1 61.8 0 79.7 0 115.3V432c0 44.2 35.8 80 80 80H396.7c35.6 0 53.5-43.1 28.3-68.3L68.3 87zM504.2 403.6c4.9 2.7 10.8 2.7 15.7 0l48-27c5-2.8 8.2-8.2 8.2-13.9V309.4c0-5.8-3.1-11.1-8.2-13.9l-48-27c-4.9-2.7-10.8-2.7-15.7 0l-48 27c-5 2.8-8.2 8.2-8.2 13.9v53.3c0 5.8 3.1 11.1 8.2 13.9l48 27zM192 64a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zM384 288a32 32 0 1 0 0-64 32 32 0 1 0 0 64z"/></svg>'
        imgsrc = '<svg xmlns="http://www.w3.org/2000/svg" height="24" width="27" viewBox="0 0 576 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.--><path fill="#008000" d="M252.4 103.8l27 48c2.8 5 8.2 8.2 13.9 8.2l53.3 0c5.8 0 11.1-3.1 13.9-8.2l27-48c2.7-4.9 2.7-10.8 0-15.7l-27-48c-2.8-5-8.2-8.2-13.9-8.2H293.4c-5.8 0-11.1 3.1-13.9 8.2l-27 48c-2.7 4.9-2.7 10.8 0 15.7zM68.3 87C43.1 61.8 0 79.7 0 115.3V432c0 44.2 35.8 80 80 80H396.7c35.6 0 53.5-43.1 28.3-68.3L68.3 87zM504.2 403.6c4.9 2.7 10.8 2.7 15.7 0l48-27c5-2.8 8.2-8.2 8.2-13.9V309.4c0-5.8-3.1-11.1-8.2-13.9l-48-27c-4.9-2.7-10.8-2.7-15.7 0l-48 27c-5 2.8-8.2 8.2-8.2 13.9v53.3c0 5.8 3.1 11.1 8.2 13.9l48 27zM192 64a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zM384 288a32 32 0 1 0 0-64 32 32 0 1 0 0 64z"/></svg>'
        status_text = '<div style="font-weight: bold; color:green;">' + IMAGES.DN.status + '</div>';
    }
    else if (value == 2) {
        // console.log("level2");                
        imgsrc = `<i class="fa-solid fa-hill-rockslide fa-lg" style="color:orange;"></i>`;
        imgsrc = '<svg xmlns="http://www.w3.org/2000/svg" height="24" width="27" viewBox="0 0 576 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.--><path fill="#ffa500" d="M252.4 103.8l27 48c2.8 5 8.2 8.2 13.9 8.2l53.3 0c5.8 0 11.1-3.1 13.9-8.2l27-48c2.7-4.9 2.7-10.8 0-15.7l-27-48c-2.8-5-8.2-8.2-13.9-8.2H293.4c-5.8 0-11.1 3.1-13.9 8.2l-27 48c-2.7 4.9-2.7 10.8 0 15.7zM68.3 87C43.1 61.8 0 79.7 0 115.3V432c0 44.2 35.8 80 80 80H396.7c35.6 0 53.5-43.1 28.3-68.3L68.3 87zM504.2 403.6c4.9 2.7 10.8 2.7 15.7 0l48-27c5-2.8 8.2-8.2 8.2-13.9V309.4c0-5.8-3.1-11.1-8.2-13.9l-48-27c-4.9-2.7-10.8-2.7-15.7 0l-48 27c-5 2.8-8.2 8.2-8.2 13.9v53.3c0 5.8 3.1 11.1 8.2 13.9l48 27zM192 64a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zM384 288a32 32 0 1 0 0-64 32 32 0 1 0 0 64z"/></svg>'
        status_text = '<div style="font-weight: bold; color:orange;">' + IMAGES.DC.status + '</div>';
    }
    else if (value == 3) {
        //console.log("level3");
        imgsrc = `<i class="fa-solid fa-hill-rockslide fa-lg" style="color:red;"></i>`;
        imgsrc = '<svg xmlns="http://www.w3.org/2000/svg" height="24" width="27" viewBox="0 0 576 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.--><path fill="#ff0000" d="M252.4 103.8l27 48c2.8 5 8.2 8.2 13.9 8.2l53.3 0c5.8 0 11.1-3.1 13.9-8.2l27-48c2.7-4.9 2.7-10.8 0-15.7l-27-48c-2.8-5-8.2-8.2-13.9-8.2H293.4c-5.8 0-11.1 3.1-13.9 8.2l-27 48c-2.7 4.9-2.7 10.8 0 15.7zM68.3 87C43.1 61.8 0 79.7 0 115.3V432c0 44.2 35.8 80 80 80H396.7c35.6 0 53.5-43.1 28.3-68.3L68.3 87zM504.2 403.6c4.9 2.7 10.8 2.7 15.7 0l48-27c5-2.8 8.2-8.2 8.2-13.9V309.4c0-5.8-3.1-11.1-8.2-13.9l-48-27c-4.9-2.7-10.8-2.7-15.7 0l-48 27c-5 2.8-8.2 8.2-8.2 13.9v53.3c0 5.8 3.1 11.1 8.2 13.9l48 27zM192 64a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zM384 288a32 32 0 1 0 0-64 32 32 0 1 0 0 64z"/></svg>'
        status_text = '<div style="font-weight: bold; color:red;">' + IMAGES.DW.status + '</div>';
    }

    return `<div class="containerBox"><div style="margin-left: 4px;">${imgsrc}</div>${status_text}`
    },
}
}
</script>

<style dashdevice>
.card-body {
flex: 1 1 auto;
min-height: 1px;
padding: 0.50rem;
}

.text-box {
position: absolute;
height: 80%;
color: white;
text-align: center;
width: 100%;
margin: auto;
top: 0;
bottom: 0;
right: 0;
left: 0;
font-size: 30px;
}

.dataNumber {
margin-top: auto;
}

.containerBox {
position: relative;
display: inline-block;
text-align: center;

}

.img-responsive {
display: block;
max-width: 100%;
height: auto;
}

.threshold-text-box {
position: absolute;
height: 30%;
color: white;
text-align: center;
width: 100%;
margin: auto;
top: 0;
bottom: 0;
right: 0;
left: 0;
font-size: 30px;
}

.threshold-dataNumber {
margin-top: auto;
font-size: 12px;
font-weight: bold;
}

/* here */
.searchBar {
display: flex;
margin: 15px 15px 10px;
width: 93%;

}

.searchBar .form-control {
margin-left: 10px;
margin-right: 10px;
}

.searchBar>* {
margin-left: 10px;
font-size: 15px;
border: 1px solid #bbbbbb;
border-radius: 5px;
color: #494949
}

.searchBar>select {
padding-left: 1.5%;
font-weight: 800;
width: 30%;
}

.searchBar .selects select {
width: 160px;
height: 35px;
margin-right: 10px;
}

.searchResult {
/* height: 525px; */
height: 686px;
overflow: auto;
}

.group {
display: flex;
flex-wrap: wrap;
margin-left: 30px;
}

.group-item {
/* border: 5px solid rgba(229,0,0,0.7); */
display: flex;
flex-wrap: wrap;
/* width: 220px; */
width: 270px;
/* height: 240px; */
height: 214px;
margin-top: 7px;
margin-bottom: 7px;
margin-right: 9px;
margin-left: 9px;
/* margin: 9px; */
border-radius: 10px;
transition: box-shadow .3s;
justify-content: center;
align-items: center;
}

.group-item:hover {
box-shadow: 0 0 11px rgba(33, 33, 33, .2);
}

.group-item >.title {
/* width: 100%; */
/* font-size: 20px; */
/* font-weight: bold; */
}

.devicetitle {
font-size: 14px;
padding: 1%;
font-weight: bold;
position: absolute;
top: 2%;
left: 2%;
z-index: 1;
color: white;
background-color: rgb(0,0,0,0.7);
border-radius: 4px;
}

.title>a {
text-decoration: none;
color: black;

}

.underline {
width: 200px;
border-top: 1px dotted rgb(179, 179, 179);
}

.iconWrapper {
display: flex;
}

.level {
margin-left: 10px;
}

.battery {
position: absolute;
top: 2%;
right: 4%;
z-index: 1;
/* margin-left: 25px; */
}

.rfstatus {
/* margin-left: 20px; */
position: absolute;
top: 1.2%;
right: 13%;
z-index: 1;
}

.imgWrapper {
border-radius: 16px;
position: relative;
cursor: pointer;
}

.sensor_time {
text-align: center;
font-weight: bold;
font-size: 12px;
width: 115px;
background-color: rgba(0, 0, 0, .7);
margin: 0 auto;
}

.sensor_time>p {
margin-bottom: -5px;
}

.searchResult::-webkit-scrollbar {
background-color: #fff;
margin-right: 10px;
width: 10px;
border-radius: 16px;
}

.searchResult::-webkit-scrollbar-track {
background-color: #fff;
border-radius: 16px;

}

.searchResult::-webkit-scrollbar-thumb {
background-color: #babac0;
border-radius: 16px;
border: 4xp solid #fff;
}

.ri-wifi-off-fill {
color: rgb(230, 81, 0);
/* font-weight: 900; */
font-size: 1.25em;
line-height: .05em;
vertical-align: -0.075em;
}

@media (max-width: 414px) {
.group {
    justify-content: center;
    align-items: center;
}
}
</style>