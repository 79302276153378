<template>
    <div class="Weather">
        <div class="weatherWrapper">
            <img class="weatherImage" :src="weather_image" />
            <div class="weatherContent">
                <div class="weatherBox">
                    <!-- <div class="weatherTitle">{{ address_ }}</div> -->
                    <div class="weatherTitle">{{ siteName }}</div>
                    <div class="weatherTime">{{ siteWeather.ncstTime}}</div>
                    <div class="swiperwrapper">
                        <swiper ref="filterSwiper" :options="swiperOption" role="tablist">
                            <!-- 기온 -->
                            <swiper-slide role="tab">{{ siteWeather.T1H }} <span class="unit">℃</span></swiper-slide>
                            <!-- 습도 -->
                            <swiper-slide role="tab">{{ siteWeather.REH  }} <span class="unit">%</span></swiper-slide>
                            <!-- 풍속 -->
                            <swiper-slide role="tab">{{ siteWeather.WSD  }} <span class="unit">m/s</span></swiper-slide>
                            <!-- 강수량 -->
                            <swiper-slide role="tab">{{ siteWeather.RN1 }} <span class="unit">mm</span></swiper-slide>
                        </swiper>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import EventBus from '@/services/EventBus';
import loopback from '@/services/loopback';
import moment from 'moment';
import utils from '@/services/utils';

// swiper.js
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.min.css";

export default {
    name: 'Weather',
    components: {
        swiper,
        swiperSlide,
    },
    props: {
        prefix: {
            type: String,
            default: ''
        },
    },
    created: function() {
        this.userInfo = this.$store.state.auth.user_info;
        this.user = this.userInfo.user;
        this.sites = this.userInfo.sites;

        this.active_site_index = _.get(this.user, "active_site_index", 0);
        //this.active_site_name = this.user.active_site_name;
        // console.log("this.active_site_index : ", this.active_site_index)
        // setInterval(this.updateCurrentTime, 1000);    
        this.getWeatherData(this.active_site_index, this.user.active_site_name)
    },
    mounted() {
        EventBus.$on("send", (data) => {
            this.siteName = data.site
            this.getWeatherData(data.site_index, data.site)
        })
    },
    beforeDestroy(){
        EventBus.$off("send")
    },
    data() {
        return {
            siteName: "",
            active_site_index: 0,
            siteWeather: {},
            weather_image: 'images/cloud.png',
            address_ : "",
            currentTime: "",
            swiperOption: {
                autoplay: {
                    delay: 3000
                },
                spaceBetween : 80,
                slidesPerView: 1,
                centeredSlides: true,
            }
        }
    },
    methods: {
        updateCurrentTime() {
            const now = new Date();
            const hours = this.formatNumber(now.getHours());
            const minutes = this.formatNumber(now.getMinutes());
            const seconds = this.formatNumber(now.getSeconds());
            this.currentTime = `${hours}:${minutes}:${seconds}`;
        },
        formatNumber(value) {
            return value < 10 ? '0' + value : value;
        },
        getWeatherData(index, address) {
            var self = this;
            var default_site_guid = _.get(this.user, "default_site_guid");
            this.address_ = address;
            var data = {
                from: moment(new Date().setMinutes(0, 0, 0)).subtract(1,'day'),
                to: moment(new Date().setMinutes(0, 0, 0)).add(9,'hour'),
                site_guid: _.isEmpty(this.user.active_site_guids[index]) ? default_site_guid : this.user.active_site_guids[index]        
            }
            return loopback
                .method('weather', 'getWeatherStat', data)
                .then(res => {
                    if(res.data.length == 0){
                        console.log("load default data none");
                    }
                    else{
                        res.data[0].VEC = utils.getWindDirectionName(Number(res.data[0].VEC))
                        self.siteWeather = res.data[0];
                        self.siteWeather.ncstTime = self.parseFcstTime(self.siteWeather.ncstTime);
                        self.weather_image = this.getWeatherImage(self.siteWeather.PTY);
                    }
                    return Promise.resolve(self.siteWeather)
            })
        }, 
        parseFcstTime(time) {
            if(!/^(\d){12}$/.test(time)) return "Invalid Date";
                var dt = time.replace(/^(\d{4})(\d\d)(\d\d)(\d\d)(\d\d)$/, '$1-$2-$3 $4:$5');
                return moment(new Date(dt)).format("MM월 DD일 HH시 예보");
        },
        getWeatherImage(pty) {
            var image_ = 'images/cloud2.png';
            switch(pty){
                case '0':
                    image_ = 'images/sunny.png';
                    break;
                case '1':
                    image_ = 'images/rainy2.png';
                    break;
                case '2':
                    image_ = 'images/cloudy_rain.png';
                    break;
                case '3':
                    image_ = 'images/snowy2.png';
                    break;
                case '4':
                    image_ = 'images/rainy.png';
                    break;
                case '5':
                    image_ = 'images/hail.png';
                    break;
                case '6':
                    image_ = 'images/snowy.png';
                    break;
                case '7':
                    image_ = 'images/snowy.png';
                    break;                                          
            }      
            return image_;
        }
    }
    
}
</script>

<style scoped lang="scss">
@font-face {
    font-family: 'GmarketSansMedium';
    src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/GmarketSansMedium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

* {
    font-family: 'GmarketSansMedium', sans-serif;
}

.Weather {
    position: relative;
    box-sizing: border-box;
    .weatherWrapper {
        display: flex;
        .weatherImage {
            padding: 3%;
            width: 100px;
            height: 100px;
        }
        .weatherContent {
            position: relative;
            width: 100%;
            height: 100px;
            .weatherBox {
                margin-left: 1.2%;
                margin-top: 1.8%;
                .weatherTitle {
                    font-size: 20px;
                }
                .swiperwrapper {
                    width: 120px;
                    font-size: 28px;
                    .unit {
                        font-size: 18px;
                    }

                }
                .weatherTime {
                    height: 24px;
                    line-height: 24px;
                    font-size: 13px;
                    // border: 1px solid red;
                }
            }
        }
    }
}
</style>                    