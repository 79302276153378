<template>
  <div>
    <CRow>
      <CCol col="12">
        <Summation ref="summationView" @click="onClick" />
      </CCol>
    </CRow>
    <CRow>
      <CCol col="12" xl="9">
        <CCard>
          <Device ref="deviceView" @change="onChangedSite" prefix="srds_" />
        </CCard>
      </CCol>
      <CCol col="12" xl="3">
        <CCard>
          <Weather ref="weatherView" />
        </CCard>
        <CCard>
          <EventLog
            ref="eventView"
            event_height="574" 
            @update="updateSummation"
            @click="onClickEvent"
            prefix="srds_"
          />
        </CCard>
      </CCol>
    </CRow>
    <EventDialog ref="eventDialog" @click="onClickEvent" />
  </div>
</template>

<script>
import Dashboard from '@/views/srds/Dashboard.vue'
import Device from '@/views/military/Device.vue'
import EventLog from '@/views/military/EventLog.vue'
import Weather from '@/views/military/Weather.vue'
import EventDialog from '@/views/military/EventDialog.vue'


export default {
    name: "MilitaryDashboard",
    extends: Dashboard,
    components: {
      Device,
      EventLog,
      Weather,
      EventDialog
    },
    

}
</script>

<style scoped lang="scss">
@font-face {
    font-family: 'GmarketSansMedium';
    src: url('/public/css/fonts/GmarketSansTTFMedium.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
}

* {
    font-family: 'GmarketSansMedium', sans-serif;
}

.card {
  border-radius: 16px;
}
</style>